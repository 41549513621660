import { graphql, useStaticQuery } from 'gatsby';

const useSiteMetadata = (): { title: string; description: string } => {
  const data = useStaticQuery<GatsbyTypes.SITE_METADATA_QUERYQuery>(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  );
  const { title, description } = data.site?.siteMetadata ?? {};

  return { title: title ?? '?', description: description ?? '?' };
};

export default useSiteMetadata;
