import React from 'react';
import { Link } from 'gatsby';

import logo from '../img/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';

const Footer = (): JSX.Element => (
  <footer className="footer has-background-black has-text-white-ter">
    <div className="content has-text-centered">
      <img src={logo} alt="Mystyle" style={{ width: '8em', height: '10em' }} />
    </div>
    <div className="content has-text-centered has-background-black has-text-white-ter">
      <div className="container">
        <div style={{ maxWidth: '100vw' }} className="columns">
          <div className="column is-4">
            <section className="menu">
              <ul className="menu-list">
                <li>
                  <Link to="/" className="navbar-item">
                    Hjem
                  </Link>
                </li>
                <li>
                  <Link className="navbar-item" to="/about">
                    Om mig
                  </Link>
                </li>
                <li>
                  <Link className="navbar-item" to="/theory">
                    Teorien bag
                  </Link>
                </li>
                <li>
                  <Link className="navbar-item" to="/products">
                    Dine muligheder
                  </Link>
                </li>
              </ul>
            </section>
          </div>
          <div className="column is-4">
            <section>
              <ul className="menu-list">
                {/* <li>
                  <Link className="navbar-item" to="/contact">
                    Kontakt
                  </Link>
                </li> */}
              </ul>
            </section>
          </div>
          <div className="column is-4">
            <a
              title="facebook"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/ARRogKBR"
            >
              <span className="icon has-text-white-ter">
                <FontAwesomeIcon icon={faFacebook} size="2x" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
