import React from 'react';
import { Link } from 'gatsby';
import logoText from '../img/logo_text.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';

const Navbar = (): JSX.Element => {
  const [active, setActive] = React.useState(false);
  const navBarActiveClass = active ? 'is-active' : '';

  const toggleHamburger = React.useCallback(() => {
    setActive((prev) => !prev);
  }, []);

  return (
    <nav
      className="navbar is-transparent is-fixed-top is-black"
      role="navigation"
      aria-label="main-navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="Logo">
            <img src={logoText} alt="Mystyle" style={{ width: '88px' }} />
          </Link>
          {/* Hamburger menu */}
          <div
            className={`navbar-burger burger ${navBarActiveClass}`}
            data-target="navMenu"
            onClick={toggleHamburger}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div id="navMenu" className={`navbar-menu ${navBarActiveClass}`}>
          <div className="navbar-start has-text-centered">
            <Link className="navbar-item" to="/about">
              Om mig
            </Link>
            <Link className="navbar-item" to="/theory">
              Teorien bag
            </Link>
            <Link className="navbar-item" to="/products">
              Muligheder
            </Link>
          </div>
          <div className="navbar-end has-text-centered">
            <a
              className="navbar-item"
              title="facebook"
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/ARRogKBR"
            >
              <span className="icon">
                <FontAwesomeIcon icon={faFacebookSquare} size="2x" />
              </span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
